import React, { useState } from "react";
import "./EnquiryForm.css";
import { useNavigate } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import { IoMdClose } from "react-icons/io";

const EnquiryForm = () => {
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+1 ");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const toggleForm = () => {
    setShowForm(!showForm);
  };
  const handleChange = (e) => {
    const enteredValue = e.target.value;

    if (enteredValue.startsWith("+1 ")) {
      setPhoneNumber(enteredValue);
    } else if (enteredValue === "+1") {
      setPhoneNumber(enteredValue + " ");
    }
  };

  const handleKeyDown = (e) => {
    // Allow only numeric input
    if (
      !/[0-9]/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== " " &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight" &&
      e.key !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate email and message fields
    const emailInput = document.getElementById("email");
    const nameInput = document.getElementById("name");

    const phoneInput = document.getElementById("phone");

    if (
      emailInput.value === "" ||
      nameInput.value === "" ||
      phoneInput.value === "+1 "
    ) {
      alert("Please enter all required fields.");
      return;
    }

    const response = await fetch(
      "https://test.amzifysolutions.com/api/email/V1/contactInfoMail",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userName: name,
          userPhoneNumber: phoneNumber,
          userMessage: message,
          userEmail: email,
        }),
      }
    );

    const responseData = await response.json();
    navigate("/thank-you/");

    // alert(responseData.msg);
    document.getElementById("myForm").reset();
    setPhoneNumber("+1 ");
  };
  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={8}
        color="149, 191, 71"
        outerAlpha={0.2}
        innerScale={0.7}
        outerScale={5}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
      <div className={`sidebar-form ${showForm ? "show" : ""}`}>
        <IoMdClose onClick={toggleForm} size={25} color="#95bf47" />
        <div className="call-action" onClick={toggleForm}>
          <span>Contact Form</span>
        </div>

        <h3>Contact Form</h3>

        <form onSubmit={handleSubmit} id="myForm">
          <div className="form-group">
            <label>Name*</label>
            <input
              type="text"
              id="name"
              placeholder="Your name"
              onChange={(e) => setName(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Phone Number*</label>
            <input
              id="phone"
              value={phoneNumber}
              onKeyDown={handleKeyDown}
              onChange={(e) => handleChange(e)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Message</label>
            <input
              type="text"
              placeholder="Your message"
              onChange={(e) => setMessage(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label>Email Address*</label>
            <input
              type="text"
              id="email"
              placeholder="Your email"
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
            />
          </div>

          <input type="submit" value="Submit Request" id="submit-btn" />
        </form>
      </div>
    </>
  );
};

export default EnquiryForm;
